import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faChevronUp, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ProductCardComponent } from './product-card/product-card.component';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './admin/login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ProductenComponent } from './admin/producten/producten.component';
import { NewProductComponent } from './admin/new-product/new-product.component';
import { ChangeProductComponent } from './admin/change-product/change-product.component';
import { ProductComponent } from './product/product.component';
import { ChangeProductlinkComponent } from './admin/change-productlink/change-productlink.component';
import { NewProductlinkComponent } from './admin/new-productlink/new-productlink.component';
const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'product/:_id', component: ProductComponent},
  {path: 'admin/dashboard', component: DashboardComponent},
  {path: 'admin/producten', component: ProductenComponent},
  {path: 'admin/producten/new', component: NewProductComponent},
  {path: 'admin/producten/:_id', component: ChangeProductComponent},
  {path: 'admin/productlink/new/:_id', component: NewProductlinkComponent},
  {path: 'admin/productlink/change/:_id', component: ChangeProductlinkComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];
@NgModule({
  declarations: [
    AppComponent,
    ProductCardComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    ProductenComponent,
    NewProductComponent,
    ChangeProductComponent,
    ProductComponent,
    ChangeProductlinkComponent,
    NewProductlinkComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes, {enableTracing: false, scrollPositionRestoration: 'enabled'}
    ),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook);
    library.addIcons(faInstagram);
    library.addIcons(faPhone);
    library.addIcons(faEnvelope);
    library.addIcons(faChevronUp);
    library.addIcons(faChevronDown);
  }
 }
