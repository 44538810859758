import { Productlink } from './../../../models/product_link';
import { Product } from './../../../models/product';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { ProductService } from 'src/services/product.service';

@Component({
  selector: 'app-change-product',
  templateUrl: './change-product.component.html',
  styleUrls: ['./change-product.component.scss']
})
export class ChangeProductComponent implements OnInit {
  productForm;
  fotoEncoded;
  success = false;
  error = false;
  loading = false;
  productId;
  product: Product;
  links: Array<Productlink> = [];
  producten: Array<Product> = [];

  constructor(private authService: AuthService, private productService:ProductService, private formBuilder:FormBuilder, private router: Router, private activatedRoute:ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params);
      this.productId = params.get('_id');
      console.log(this.productId);
      this.productService.getProduct(this.productId).then(data => {
        this.productService.getProducten().then(data3 => {
          this.producten = data3;
          this.productService.getProductlinks(this.productId).then(data2 => {
            this.links = data2;
            console.log(this.links);
            console.log(this.producten);
            this.fillForm(data);
          })
        })
      })
    });
    this.productForm = this.formBuilder.group({
      naam: ['',[Validators.required]],
      prijs: ['', [Validators.required]],
      weekprijs: ['', [Validators.required]],
      omschrijving: ['', [Validators.required]],
      foto:[''],
    })
  }
  get naam() { return this.productForm.get('naam'); }
  get prijs() { return this.productForm.get('prijs'); }
  get weekprijs() { return this.productForm.get('weekprijs'); }
  get omschrijving() { return this.productForm.get('omschrijving'); }
  get foto() { return this.productForm.get('foto'); }

  ngOnInit(): void {
    console.log(this.authService.isLoggedIn());
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['']);
    }
  }
  fillForm(data) {
    console.log('get product: ');
    console.log(data);
    this.product = data;
    this.productForm = this.formBuilder.group({
      naam: [this.product.naam,[Validators.required]],
      prijs: [this.product.prijs, [Validators.required]],
      weekprijs: [this.product.weekprijs, [Validators.required]],
      omschrijving: [this.product.omschrijving, [Validators.required]],
      foto:[''],
    });
    this.fotoEncoded = this.product.foto;
  }
  fileToBase64(foto) {
    return new Promise(resolve => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function(event) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(foto);
    });
  };
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      let foto = fileList[0];
      this.fileToBase64(foto).then(result => {
        //console.log(result);
        this.fotoEncoded = result;
      });
    } else {
      this.fotoEncoded = '';
      console.log('error file')
    }
  }

  onSubmit(formData) {
    this.loading = true;
    console.log(formData);
    formData.foto = this.fotoEncoded;
    formData._id = this.productId;
    console.log('submitform');
    this.productService.updateProduct(formData)
    .subscribe(res => {
      this.loading = false;
      console.log(res);
      this.success = true;
    }, err => {
      this.loading = false;
      console.log(err);
      this.error = true;
    })
  }
  verwijder(id) {
    this.loading = true;
    this.productService.deleteProductlink(id).subscribe(data =>{
      console.log(data);
      this.ngOnInit();
    }, err => {
      console.log(err);
      this.loading = false;
    });
  }

}
