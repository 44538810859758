import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from 'src/models/product';
import { AuthService } from 'src/services/auth.service';
import { ProductService } from 'src/services/product.service';

@Component({
  selector: 'app-new-productlink',
  templateUrl: './new-productlink.component.html',
  styleUrls: ['./new-productlink.component.scss']
})
export class NewProductlinkComponent implements OnInit {
  productId;
  productlinkForm;
  producten: Array<Product>;
  success = false;
  error = false;
  loading = true;

  constructor(private authService: AuthService, private productService:ProductService, private formBuilder:FormBuilder, private router: Router, private activatedRoute:ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params);
      this.productId = params.get('_id');
      console.log(this.productId);
      productService.getProducten().then(data => {
        this.producten = data;
        this.fillForm();
        this.loading = false;
      })
    });
    this.productlinkForm = this.formBuilder.group({
      product: ['',[Validators.required]],
      productlink: ['', [Validators.required]],
      korting: ['',],
    })
  }
  get product() { return this.productlinkForm.get('product'); }
  get productlink() { return this.productlinkForm.get('productlink'); }
  get korting() { return this.productlinkForm.get('korting'); }

  ngOnInit(): void {
    console.log(this.authService.isLoggedIn());
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['']);
    }
  }


  fillForm() {
    let productnaam;
    this.producten.forEach(product => {
      if(product._id == this.productId) {
        productnaam = product.naam;
      }
    });
    this.productlinkForm = this.formBuilder.group({
      product: [productnaam,[Validators.required]],
      productlink: ['', [Validators.required]],
      korting: ['',]
    });
  }

  onSubmit(formData) {
    this.loading = true;
    console.log(formData);
    formData.product = this.productId;
    console.log('submitform');
    this.productService.addProductlink(formData)
    .subscribe(res => {
      this.loading = false;
      console.log(res);
      this.success = true;
    }, err => {
      this.loading = false;
      console.log(err);
      this.error = true;
    })
  }
}
