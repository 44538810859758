import { Productlink } from './../../../models/product_link';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from 'src/models/product';
import { AuthService } from 'src/services/auth.service';
import { ProductService } from 'src/services/product.service';
@Component({
  selector: 'app-change-productlink',
  templateUrl: './change-productlink.component.html',
  styleUrls: ['./change-productlink.component.scss']
})
export class ChangeProductlinkComponent implements OnInit {
  productId;
  productlinkForm;
  gelinktproduct;
  linkId;
  link: Productlink;
  producten: Array<Product>;
  success = false;
  error = false;
  loading = true;

  constructor(private authService: AuthService, private productService:ProductService, private formBuilder:FormBuilder, private router: Router, private activatedRoute:ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params);
      this.linkId = params.get('_id');
      console.log(this.productId);

      productService.getProductlink(this.linkId).then(data => {
        this.link = data;
        productService.getProducten().then(data2 => {
          this.producten = data2;
          this.loading = false
          this.fillForm();
        });
      });

    });
    this.productlinkForm = this.formBuilder.group({
      product: ['',[Validators.required]],
      productlink: ['', [Validators.required]],
      korting: ['',],
    })
  }
  get product() { return this.productlinkForm.get('product'); }
  get productlink() { return this.productlinkForm.get('productlink'); }
  get korting() { return this.productlinkForm.get('korting'); }

  ngOnInit(): void {
    console.log(this.authService.isLoggedIn());
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['']);
      this.fillForm();
    }
  }


  fillForm() {
    console.log('fill form')
    let productnaam;
    this.producten.forEach(product => {
      if(product._id == this.link.product) {
        productnaam = product.naam;
      }
      if(product._id == this.link.productlink) {
        this.gelinktproduct = product.naam;
      }
    });
    this.productlinkForm = this.formBuilder.group({
      product: [productnaam,[Validators.required]],
      product_link: ["", [Validators.required]],
      korting: [this.link.korting,]
    });
  }

  onSubmit(formData) {
    this.loading = true;
    console.log(formData);
    formData.product = this.productId;
    console.log('submitform');
    this.productService.updateProductlink(formData)
    .subscribe(res => {
      this.loading = false;
      console.log(res);
      this.success = true;
    }, err => {
      this.loading = false;
      console.log(err);
      this.error = true;
    })
  }
}
