<h1 style="padding-top: 100px;">Wijzig Product link</h1>
<div class="formContainer">
  <form [formGroup]="productlinkForm" (ngSubmit)="onSubmit(productlinkForm.value)">
    <label for='product'>Product</label>
    <input id="product" disabled type="text" formControlName="product">
    <div class="error" [class.vissible]="product.invalid && (product.dirty || product.touched)">Product is verplicht</div>
    <label for="productlink">Gelinkt product: {{gelinktproduct}}</label>
    <select id="productlink" formControlName="productlink">
      <option *ngFor="let x of producten" [value]="x._id" [selected]="x._id == link.productlink">{{x.naam}}</option>
    </select>
    <div class="error" [class.vissible]="productlink.invalid && (productlink.dirty || productlink.touched)">Gelinkt product is verplicht</div>
    <label id="korting">Korting</label>
    <input class="korting" type="number" formControlName="korting">
    <button type="submit" [disabled]="product.invalid || productlink.invalid || korting.invalid">Maak Product link</button>
    <div [class.vissible]="success" class="success">Gewijzigd</div>
    <div [class.vissible]="error" class="error">Iets ging mis, probeer opnieuw</div>
  </form>
</div>
<div *ngIf="loading" class="loaderContainer">
  <div class="loader"></div>
</div>
