import { Product } from './../../models/product';
import { ProductService } from './../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Productlink } from 'src/models/product_link';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],

})
export class ProductComponent implements OnInit {
  product: Product;
  productId;
  productlinks: Array<Productlink>;
  producten: Array<Product>;
  dagen = 1;
  math = Math;
  constructor( private productService: ProductService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log(params);
      this.productId = params.get('_id');
      console.log(this.productId);
      this.productService.getProduct(this.productId).then(data => {
        console.log('get product: ');
        console.log(data);
        this.product = data;
        this.productService.getProductlinks(this.productId).then(data2 => {
          this.productlinks = data2;
        })
      })
      this.productService.getProducten().then(data3 => {
        this.producten = data3;
      })
    });
  }

  dagenChange($event) {
    this.dagen = $event.target.value;
  }

}
