import { Productlink } from './../../models/product_link';
import { ProductService } from 'src/services/product.service';
import { Product } from './../../models/product';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;
  @Input() producten: Array<Product>;
  @Input() page;
  productlinks: Array<Productlink>;

  constructor(private productService:ProductService) {
  }

  ngOnInit(): void {
    console.log(this.product.naam);
    this.productService.getProductlinks(this.product._id).then(data => {
      this.productlinks = data;
    })
  }

}
