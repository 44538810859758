<h1 style="padding-top: 100px;">Producten</h1>
<button [routerLink]="['/admin/producten/new']">+ nieuw</button>
<div class="tabel">
  <div class="row" *ngFor="let product of producten">
    <div>
      <div (click)="plaatsup(product)"><fa-icon [icon]="['fas','chevron-up']" ></fa-icon></div>
      <div>{{product.plaats}}</div>
      <div (click)="plaatsdown(product)"><fa-icon [icon]="['fas','chevron-down']"></fa-icon></div>
    </div>
    <div>{{product.naam}}</div>
    <div class="buttons">
      <button [routerLink]="['/admin/producten',product._id]">Bewerk</button>
      <button (click)="verwijder(product)">Verwijder</button>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loaderContainer">
  <div class="loader"></div>
</div>
