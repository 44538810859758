import { Product } from './../../models/product';
import { ProductService } from './../../services/product.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  producten: Product[];

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.productService.getProducten().then( data => {
      this.producten = data;
      this.producten.sort((a,b) => a.plaats - b.plaats);
    })
  }

}
