<h1 style="padding-top: 100px;">Nieuw product</h1>
<div class="formContainer">
  <form [formGroup]="productForm" (ngSubmit)="onSubmit(productForm.value)">
    <label for='naam'>Naam</label>
    <input id="naam" type="text" formControlName="naam" >
    <div class="error" [class.vissible]="naam.invalid && (naam.dirty || naam.touched)">Naam is verplicht</div>
    <label for="prijs">Prijs</label>
    <input id="prijs" type="number" formControlName="prijs">
    <div class="error" [class.vissible]="prijs.invalid && (prijs.dirty || prijs.touched)">Prijs is verplicht</div>
    <label for="weekrpijs">Weekprijs</label>
    <input id="weekprijs" type="number" formControlName="weekprijs">
    <div class="error" [class.vissible]="weekprijs.invalid && (weekprijs.dirty || weekprijs.touched)">Weekprijs is verplicht</div>
    <label id="omschrijving">Omschrijving</label>
    <textarea class="omschrijving" type="text" formControlName="omschrijving"></textarea>
    <div class="error" [class.vissible]="omschrijving.invalid && (omschrijving.dirty || omschrijving.touched)">Omschrijving is verplicht</div>
    <label for='foto'>Foto</label>
    <input id="foto" type="file" (change)='fileChange($event)' accept=".jpg, .png, .jpeg">
    <!-- <div class="error" [class.vissible]="(fotoEncoded == undefined || fotoEncoded == '')">Foto is verplicht</div> -->
    <img [src]="fotoEncoded">
    <button type="submit" [disabled]="naam.invalid || prijs.invalid">Maak Product</button>
    <div [class.vissible]="success" class="success">Aangemaakt</div>
    <div [class.vissible]="error" class="error">Iets ging mis, probeer opnieuw</div>
  </form>
</div>
<div *ngIf="loading" class="loaderContainer">
  <div class="loader"></div>
</div>
