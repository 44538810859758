import { ProductService } from './../../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { Product } from 'src/models/product';

@Component({
  selector: 'app-producten',
  templateUrl: './producten.component.html',
  styleUrls: ['./producten.component.scss']
})
export class ProductenComponent implements OnInit {
  producten: Array<Product>;
  loading = true;

  constructor(private authService: AuthService,private productService: ProductService, private router: Router) { }

  ngOnInit(): void {
    console.log(this.authService.isLoggedIn());
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['']);
    }
    this.productService.getProducten().then(data => {
      this.producten = data;
      this.loading = false;
      this.producten.sort((a,b) => a.plaats - b.plaats )
    })


  }
  verwijder(product:Product) {
    this.loading = true;
    let plaatsverwijderd = product.plaats;
    this.productService.deleteProduct(product._id).subscribe(data =>{
      console.log(data);
      for (let i = 0; i < this.producten.length; i++) {
        if (this.producten[i].plaats > plaatsverwijderd) {
          this.plaatsup(this.producten[i]);
        }
      }
      this.ngOnInit();
    }, err => {
      console.log(err);
      this.loading = false;
    });
  }
  plaatsup(product){
    console.log('up');
    for (let i = 0; i < this.producten.length; i++) {
      if (this.producten[i].plaats == product.plaats - 1) {
        this.producten[i].plaats = product.plaats;
        product.plaats = product.plaats - 1;
        this.productService.updateProduct(this.producten[i])
        .subscribe(res => {
          this.loading = false;
          console.log(res);
          this.productService.updateProduct(product)
          .subscribe(res => {
            this.loading = false;
            console.log(res);
            this.ngOnInit();
          }, err => {
            this.loading = false;
            console.log(err);
            this.ngOnInit();
          })
        }, err => {
          this.loading = false;
          console.log(err);
        })
        break;
      }
    };
  }
  plaatsdown(product){
    console.log('down');
    for (let i = 0; i < this.producten.length; i++) {
      if (this.producten[i].plaats == product.plaats + 1) {
        this.producten[i].plaats = product.plaats;
        product.plaats = product.plaats + 1;
        this.productService.updateProduct(this.producten[i])
        .subscribe(res => {
          this.loading = false;
          console.log(res);
          this.productService.updateProduct(product)
          .subscribe(res => {
            this.loading = false;
            console.log(res);
            this.ngOnInit();
          }, err => {
            this.loading = false;
            console.log(err);
            this.ngOnInit();
          })
        }, err => {
          this.loading = false;
          console.log(err);
        })
        break;
      }
    };
  }

}
