<nav id="nav">
  <div routerLink='home'  id="logo">
    <img src="../assets/Logo_TP_verhuur.png">
  </div>
  <div id="end">
    <!-- <div (click)="goToProducten()">
      PRODUCTEN
    </div> -->
    <div>
      <a href="tel:0473949339"><fa-icon [icon]="['fas','phone']"></fa-icon></a>
    </div>
    <div>
      <a href="mailto:info@tpverhuur.be"><fa-icon [icon]="['fas','envelope']"></fa-icon></a>
    </div>
    <div *ngIf="loggedIn" routerLink='admin/dashboard'>Dashboard</div>
    <div *ngIf="loggedIn" (click)="logout()">Logout</div>
  </div>
</nav>
<router-outlet>
</router-outlet>
<footer>
  <div id="info">
    <div><a href="mailto:info@tpverhuur.be"><fa-icon [icon]="['fas','envelope']"></fa-icon>info@tpverhuur.be</a></div>
    <div><a href="tel:0473949339"><fa-icon [icon]="['fas','phone']"></fa-icon>04 73 94 93 39</a></div>
    <div><a target="_blank" href="https://www.facebook.com/TPverhuur-103536305223951"><fa-icon [icon]="['fab','facebook']"></fa-icon></a>
      <a target="_blank" href="https://www.instagram.com/tpverhuur/"><fa-icon [icon]="['fab','instagram']"></fa-icon></a></div>
    </div>
    <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
    &q=Sigmashop - Goe-Decor - Olen
    &zoom=17" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </footer>



