import { ProductService } from './../../../services/product.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {
  productForm;
  fotoEncoded;
  producten;
  productaantal;
  success = false;
  error = false;
  loading = false;
  constructor(private authService: AuthService, private productService:ProductService, private formBuilder:FormBuilder, private router: Router) {
    this.productForm = this.formBuilder.group({
      naam: ['',[Validators.required]],
      prijs: ['', [Validators.required]],
      weekprijs: ['', [Validators.required]],
      omschrijving: ['', [Validators.required]],
      extra: [''],
      foto:[''],
    })
  }
  get naam() { return this.productForm.get('naam'); }
  get prijs() { return this.productForm.get('prijs'); }
  get weekprijs() { return this.productForm.get('weekprijs'); }
  get omschrijving() { return this.productForm.get('omschrijving'); }
  get extra() { return this.productForm.get('extra'); }
  get foto() { return this.productForm.get('foto'); }

  ngOnInit(): void {
    console.log(this.authService.isLoggedIn());
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['']);
    }
    this.productService.getProducten().then(data => {
      console.log('data');
      console.log(data);
      this.producten = data;
      this.productaantal = this.producten.length;
      console.log(this.producten.length);
    });
  }
  fileToBase64(foto) {
    return new Promise(resolve => {
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function(event) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(foto);
    });
  };
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      let foto = fileList[0];
      this.fileToBase64(foto).then(result => {
        //console.log(result);
        this.fotoEncoded = result;
      });
    } else {
      this.fotoEncoded = '';
      console.log('error file')
    }
  }

  onSubmit(formData) {
    this.loading = true;
    console.log(formData);
    formData.foto = this.fotoEncoded;
    formData.plaats = this.productaantal +1;
    console.log("plaats: " + formData.plaats + " / " + this.productaantal)
    console.log('submitform');
    this.productService.addProduct(formData)
    .subscribe(res => {
      this.loading = false;
      console.log(res);
      this.success = true;
    }, err => {
      this.loading = false;
      console.log(err);
      this.error = true;
    })
  }

}
