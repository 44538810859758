<div class="card" [class.homecard]="page == 'home'" [class.productcard]="page == 'product'" [routerLink]="['/product',product._id]" >
  <div class="img" [ngStyle]="{'background-image' : 'url(' + product.foto + ')'}">

  </div>
  <div class="text">
    <h2 class="naam">{{product.naam}}</h2>
    <div class="prijs">€{{product.prijs}} per dag</div>
    <ng-container *ngFor="let y of productlinks">
      <ng-container *ngFor="let x of producten">
        <ng-container *ngIf="x._id == y.productlink">
          <div  class="extra" *ngIf="page=='home' && y.korting != undefined && y.korting != ''">+ {{x.naam}} = - {{y.korting}}%</div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>
</div>
