import { Productlink } from './../models/product_link';
import { Product } from './../models/product';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiUrl = "/api"

  constructor(private http: HttpClient) { }

  getProducten() {
    return this.http.get<Product[]>(this.apiUrl + "/all").toPromise();
  }
  getProduct(_id) {
    return this.http.get<Product>(this.apiUrl + "/" + _id).toPromise();
  }

  addProduct(product): Observable<Product> {
    console.log('addProduct');
    return this.http.post<Product>(this.apiUrl + "/product", product);
  }

  updateProduct(product): Observable<Product> {
    console.log(product);
    return this.http.put<Product>(this.apiUrl + "/" + product._id, product);
  }

  deleteProduct(id): Observable<Product> {
    return this.http.delete<Product>(this.apiUrl + "/" + id);
  }

  getProductlinks(id) {
    console.log('get productlinks ' + id);
    return this.http.get<Productlink[]>(this.apiUrl + "/productlink/all/" + id).toPromise();
  }
  getProductlink(_id) {
    return this.http.get<Productlink>(this.apiUrl + "/productlink/" + _id).toPromise();
  }

  addProductlink(product): Observable<Productlink> {
    console.log('addProductlink');
    return this.http.post<Productlink>(this.apiUrl + "/productlink", product);
  }

  updateProductlink(product): Observable<Productlink> {
    console.log(product);
    return this.http.put<Productlink>(this.apiUrl + "/productlink/" + product._id, product);
  }

  deleteProductlink(id): Observable<Productlink> {
    return this.http.delete<Productlink>(this.apiUrl + "/productlink/" + id);
  }
}
