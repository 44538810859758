<div id="banner">
  <div id="logo">
    <img src="../assets/Logo_TP_verhuur.jpg">
  </div>
  <div id="text">
    <h1>Verhuur van werkmaterialen</h1>
    <a href="mailto:info@tpverhuur.be"><button><fa-icon [icon]="['fas','envelope']"></fa-icon>info@tpverhuur.be</button></a>
    <a href="tel:0473949339"><button><fa-icon [icon]="['fas','phone']"></fa-icon>04 73 94 93 39</button></a>
  </div>
</div>
<div id="producten">
  <app-product-card *ngFor="let product of producten"
    [product]="product"
    [producten]="producten"
    [page]="'home'">

  </app-product-card>

</div>
