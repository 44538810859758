import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm;
  showError;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router) {
    this.loginForm = this.formBuilder.group({
      username: '',
      wachtwoord: '',
    })
  }
  ngOnInit(): void {
  }
  onSubmit(formData) {
    console.log(formData);
    this.authService.login(formData)
    .subscribe(res => {
      console.log(res);
      localStorage.setItem('tp_verhuur_token', res.token);
      this.router.navigate(['admin/dashboard']);
      },
      err => {
        console.log(err.error);
        this.showError = err.error;
      }
    );
  }

}
