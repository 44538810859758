import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = "/api"
  constructor(private http: HttpClient, private router: Router) { }

  login(data): Observable<any> {
    console.log('login service')
    return this.http.post<any>(this.apiUrl + '/login', data);
  }

  logout() {
    localStorage.removeItem('tp_verhuur_token');
    this.router.navigate(['']);
  }

  isLoggedIn(): boolean {
    if(localStorage.getItem('tp_verhuur_token')) {
      return true;
    } else {
      return false;
    }
  }
}
