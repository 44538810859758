<div id="product">
  <div id="text">
    <h1 id="naam">{{product.naam}}</h1>
    <h2 id="prijs">€{{product.prijs}} per dag</h2>
    <h2 id="weekprijs">€{{product.weekprijs}} per week</h2>
    <pre id="omschrijving">{{product.omschrijving}}</pre>
    <div id="buttons">
      <a href="mailto:info@tpverhuur.be"><button><fa-icon [icon]="['fas','envelope']"></fa-icon> huur aanvragen</button></a>
      <a href="tel:0473949339"><button><fa-icon [icon]="['fas','phone']"></fa-icon> huur aanvragen</button></a>
    </div>
  </div>
  <div id="img">
    <img [src]="product.foto">
  </div>
</div>
<h2 *ngIf="productlinks.length > 0">Krijg korting als je dit huurt in combinatie met onderstaande producten:</h2>
<div id="productlinks">
  <ng-container *ngFor="let link of productlinks">
    <ng-container *ngFor="let x of producten">
      <div class="productlink" *ngIf="x._id == link.productlink">
        <app-product-card
        [product]="x"
        [producten]="producten"
        [page]="'product'">
      </app-product-card>
      <div class="end">Aantal dagen: {{dagen}}</div>
      <div class="end" *ngIf="dagen < 7">Totaal prijs 2 producten zonder korting:<b> {{math.round((x.prijs*dagen + product.prijs*dagen)*100)/100}}</b></div>
      <div class="end extra" *ngIf="dagen < 7">Totaal prijs 2 producten met korting:<b> {{math.round(((x.prijs*dagen + product.prijs*dagen)-(x.prijs*dagen + product.prijs*dagen)*(link.korting/100))*100)/100}}</b></div>
      <div class="end" *ngIf="dagen >= 7">Totaal prijs 2 producten zonder korting:<b> {{math.round(((x.weekprijs/7)*dagen + (product.weekprijs/7)*dagen)*100)/100}}</b></div>
      <div class="end extra" *ngIf="dagen >= 7">Totaal prijs 2 producten met korting:<b> {{math.round((((x.weekprijs/7)*dagen + (product.weekprijs/7)*dagen)-((x.weekprijs/7)*dagen + (product.weekprijs/7)*dagen)*(link.korting/100))*100)/100}}</b></div>
    </div>
  </ng-container>
</ng-container>
</div>



