import { AuthService } from './../services/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'tp-verhuur';
  loggedIn;
  elementOgPosition: any = 0;
  elementPosition;
  sticky;
  constructor( public router: Router, private authService: AuthService) {
    router.events.subscribe(val => {
      this.elementOgPosition = 0;
    });
  }

  ngOnInit(): void {
    AOS.init();
    this.loggedIn = this.authService.isLoggedIn();
    console.log(this.loggedIn);
  }
  @HostListener('window:scroll', ['$event'])
  handleScroll(){
    this.elementPosition = document.getElementById('nav').getBoundingClientRect().top;
    const windowScroll = window.pageYOffset;
    if (this.elementOgPosition === 0){
      this.elementOgPosition = this.elementPosition;
    }
    if (0 >= this.elementPosition){
      this.sticky = true;
    } else {
      this.sticky = false;
    }
    if (windowScroll <= this.elementOgPosition){
      this.sticky = false;
    }
  }

  logout() {
    this.authService.logout();
    this.ngOnInit();
  }

  goToProducten() {
    this.router.navigate(['/home'], {fragment: 'producten'}).then(res => {
      console.log(res);
      let producten = document.getElementById('producten');
      console.log("producten", producten)
      if (producten != undefined) producten.scrollIntoView();
    })
  }
}

